<template>
  <div class="invite">
    <div class="head">
      <i class="el-icon-receiving"></i>
      <span>邀请好友</span>
    </div>

    <div class="middle">
      <div class="title-wrap">
        <div>邀请好友</div>
        <div>快推荐应必客给您的好友吧，分享其实就是一种快乐。</div>
      </div>
      <div class="copy-pan">
        <div class="qr-code">
          <img src="http://dummyimage.com/140x140/7C83FD" alt="" srcset="" />
        </div>
        <div class="info">
          <div class="info-row">邀请链接</div>
          <div class="info-row">
            <el-input
              size="medium"
              placeholder="请输入内容"
              value="http://www.in-peak.com/"
            >
              <template slot="append">复制链接</template>
            </el-input>
          </div>
          <div class="info-row">
            <el-input
              size="medium"
              placeholder="请输入内容"
              value="http://www.in-peak.com/"
            >
              <template slot="append">复制链接</template>
            </el-input>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom">
      <div class="title">邀请记录</div>
      <table class="table">
        <tbody>
          <tr>
            <th align="left">邀请用户</th>
            <th width="50%">邀请时间</th>
          </tr>
        </tbody>
      </table>
      <el-empty style="margin-top: 50px" description="暂无数据"></el-empty>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.invite {
  .head {
    display: flex;
    align-items: center;
    background: #ffffff;
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    padding: 0px 25px;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: bold;

    i {
      font-size: 16px;
      margin-right: 4px;
    }
  }

  .middle {
    overflow: hidden;
    background: url("../../../assets/images/ori/invite-bg.jpg");
    background-color: #fff;
    background-repeat: no-repeat;
    padding: 0px 70px;
    padding-bottom: 30px;

    .title-wrap {
      font-weight: bold;
      color: #fff;
      div:nth-child(1) {
        margin-top: 70px;
        font-size: 48px;
      }

      div:nth-child(2) {
        font-size: 20px;
        font-weight: bold;
        margin-top: 10px;
      }
    }

    .copy-pan {
      overflow: hidden;
      background: #fff;
      border-radius: 3px;
      box-shadow: 0px 0px 20px #dadada;
      margin-top: 55px;
      padding: 25px 30px;

      max-width: 650px;

      display: flex;

      .qr-code {
        box-shadow: 0px 0px 10px #e2e2e2;
      }

      .info {
        width: 420px;
        margin-left: 35px;

        .info-row + .info-row {
          margin-top: 15px;
        }
      }
    }
  }

  .bottom {
    background: #ffffff;
    overflow: hidden;
    padding: 25px 25px 25px 25px;
    min-height: 650px;
    position: relative;

    .title {
      height: 35px;
      line-height: 35px;
      padding: 0 20px;
      font-size: 14px;
      background: #f4f5f9;
      margin-bottom: 20px;
      border-left: 2px solid #e62d31;
    }

    .table {
      width: 100%;
      th {
        font-size: 12px;
        font-weight: normal;
        background: #f4f5f9;
        color: #666;
        height: 35px;
        text-align: center;
        padding: 0px 5px 0px 15px;
        border-bottom: 1px solid #eeeeee;
      }

      th:nth-child(1) {
        text-align: left;
      }
    }
  }
}
</style>